import React, { Component, Fragment } from 'react';
import Head from 'next/head';
import { i18n } from 'i18n';
import { isEmpty } from 'lodash';
import { fetchSite } from 'actions/site';
import { fetchFeatures } from 'actions/features';
import { updateIntercom } from 'actions/libraries';
import { fetchUser } from 'actions/user';
import { fetchLayout } from 'actions/layout';
import {
  fetchLastPublishedHubPageRevisions,
  fetchLastHubPageRevisions,
} from 'actions/hub-page-revisions';
import {
  fetchLastPublishedHomePageRevisions,
  fetchLastHomePageRevisions,
} from 'actions/home-page-revisions';
import { setHomePageRevision } from 'actions/home-page-revision';
import {
  setHubPageRevision,
  updateHubPageRevisionNonce,
} from 'actions/hub-page-revision';
import { fetchHeaderLinks } from 'actions/header-links';
import { fetchFooterLinks } from 'actions/footer-links';
import { fetchTheme } from 'actions/theme';
import { fetchProjectTags } from 'actions/project-tags';
import { fetchLiquidTemplates } from 'actions/liquid-templates';
import { fetchSettings } from 'actions/settings';
import { fetchGoogleTranslateIntegration } from 'actions/google-translate-integration';
import { fetchHub } from 'actions/hub';
import { redirectSecondaryDomainToPrimaryDomain } from 'lib/redirect-secondary-domain-to-primary-domain';
import verifySiteToken, { setSiteToken } from 'lib/site-token';
import isProduction from 'lib/is-production';
import nookies from 'nookies';
import { HubAdmin, PlatformAdmin, ProjectAdmin, SiteAdmin } from 'lib/roles';
import { initialiseAdapter } from 'lib/adapter';
import { buildImgixUrl } from 'integrations/imgix.ts';

const withParticipantLayout = (App, isInsideAdminView = false) =>
  class WithParticipantLayout extends Component {
    static async getInitialProps(ctx) {
      initialiseAdapter(ctx);

      // Fetch current site if not present
      const { currentSite } = ctx.reduxStore.getState();
      const isCurrentSitePresent =
        currentSite.doneFetching && !currentSite.fetchError;
      if (!isCurrentSitePresent) {
        initialiseAdapter(ctx);
        await ctx.reduxStore.dispatch(fetchSite(''));
      }

      // Fetch features if not present
      const { features } = ctx.reduxStore.getState();
      const isFeaturesPresent = features.doneFetching && !features.fetchError;
      if (!isFeaturesPresent) {
        initialiseAdapter(ctx);
        await ctx.reduxStore.dispatch(fetchFeatures());
      }

      if (isEmpty(ctx.reduxStore.getState()?.librariesDetails?.intercom)) {
        await ctx.reduxStore.dispatch(updateIntercom());
      }

      // Fetch current user if not present
      const { currentUser } = ctx.reduxStore.getState();

      const isCurrentUserPresent =
        currentUser.doneFetching && !currentUser.fetchError;
      if (!isCurrentUserPresent) {
        initialiseAdapter(ctx);
        await ctx.reduxStore.dispatch(fetchUser('current'));
      }

      // Reset and fetch current hub
      initialiseAdapter(ctx);
      await ctx.reduxStore.dispatch(fetchHub(ctx.query.hubPermalink));

      /**
       * Verify the token only when not inside admin view
       * Check for site onboarding feature toggle
       * If the logged in user is not an admin
       * If site is blocked
       */
      const isSiteOnboardingEnabled =
        Object.prototype.hasOwnProperty.call(
          ctx.reduxStore.getState().features.features,
          'enable_site_onboarding',
        ) &&
        ctx.reduxStore.getState().features.features['enable_site_onboarding']
          .enabled;

      const currentUserRole =
        ctx.reduxStore.getState().currentUser.user.roleName;
      const isSiteBlocked = ctx.reduxStore.getState().currentSite.site.blocked;

      const isNotAnAdmin =
        [SiteAdmin, PlatformAdmin, HubAdmin, ProjectAdmin].indexOf(
          currentUserRole,
        ) === -1;

      const shouldCheckForToken =
        !isInsideAdminView &&
        isSiteOnboardingEnabled &&
        isNotAnAdmin &&
        isSiteBlocked;

      if (shouldCheckForToken) {
        const response = await verifySiteToken(ctx);
        const { token } = ctx.query;

        // If token is not present in the cookie then check if it is present in the URL then fetch and set the token
        if (response.status === 404) {
          if (token) {
            const response = await setSiteToken(ctx, token);

            let siteAccessValue = '';

            // Fetch the siteAccessValue cookie value
            try {
              siteAccessValue = response.headers
                .get('set-cookie')
                .split('site_access=')[1]
                .split(';')[0];
            } catch {
              siteAccessValue = '';
            }

            if (!siteAccessValue) {
              ctx.res?.writeHead(307, {
                Location: '/admin/login',
              });

              ctx.res?.end();
              return;
            }

            nookies.set(ctx, 'site_access', siteAccessValue, {
              httponly: true,
              maxAge: 36000000,
              secure: isProduction,
            });

            // If the token is invalid then it will return 404
            if (response.status === 404) {
              ctx.res?.writeHead(307, {
                Location: '/admin/login',
              });

              ctx.res?.end();
              return;
            }
          } else {
            ctx.res?.writeHead(307, {
              Location: '/admin/login',
            });

            ctx.res?.end();
            return;
          }
        }
      }

      const nonceValue = ctx?.res?.getHeader('x-nonce');
      // fetch nonce header
      const config = {
        headers: {
          nonce: nonceValue,
        },
      };

      if (nonceValue) {
        ctx.reduxStore.dispatch(updateHubPageRevisionNonce(nonceValue));
      }

      // Fetch hubPageRevisions
      const showDraftRevision = ctx.query.draft;

      if (showDraftRevision) {
        // Fetch only the latestRevision
        initialiseAdapter(ctx);
        await ctx.reduxStore.dispatch(
          fetchLastHubPageRevisions(ctx.query.hubPermalink),
        );
        initialiseAdapter(ctx);
        await ctx.reduxStore.dispatch(fetchLastHomePageRevisions());

        const { hubPageRevisions, homePageRevisions } =
          ctx.reduxStore.getState();

        ctx.reduxStore.dispatch(
          setHubPageRevision(hubPageRevisions.lastRevision),
        );
        ctx.reduxStore.dispatch(
          setHomePageRevision(homePageRevisions.lastRevision),
        );
      } else {
        // Fetch only the latestPublishedRevision
        initialiseAdapter(ctx);
        await ctx.reduxStore.dispatch(
          fetchLastPublishedHubPageRevisions(ctx.query.hubPermalink),
        );

        initialiseAdapter(ctx);
        await ctx.reduxStore.dispatch(fetchLastPublishedHomePageRevisions({}));

        const { hubPageRevisions, homePageRevisions } =
          ctx.reduxStore.getState();

        if (hubPageRevisions.lastPublishedRevision !== undefined) {
          ctx.reduxStore.dispatch(
            setHubPageRevision(hubPageRevisions.lastPublishedRevision),
          );
        }

        if (homePageRevisions.lastPublishedRevision !== undefined) {
          ctx.reduxStore.dispatch(
            setHomePageRevision(homePageRevisions.lastPublishedRevision),
          );
        }
      }

      // Fetch site settings if not present to check google translation feature status
      const { siteSettings } = ctx.reduxStore.getState();
      const isSiteSettingsPresent =
        siteSettings.doneFetching && !siteSettings.fetchError;
      if (!isSiteSettingsPresent) {
        initialiseAdapter(ctx);
        await ctx.reduxStore.dispatch(fetchSettings());
      }

      // Fetch google translate settings if feature is enabled
      const isGoogleTranslationEnabled =
        ctx.reduxStore.getState().siteSettings.settings
          .googleTranslationEnabled;
      if (isGoogleTranslationEnabled) {
        initialiseAdapter(ctx);
        await ctx.reduxStore.dispatch(fetchGoogleTranslateIntegration());
      }

      initialiseAdapter(ctx);
      await Promise.all([
        ctx.reduxStore.dispatch(fetchProjectTags()),
        ctx.reduxStore.dispatch(fetchHeaderLinks()),
        ctx.reduxStore.dispatch(fetchFooterLinks()),
        ctx.reduxStore.dispatch(fetchLayout()),
        ctx.reduxStore.dispatch(fetchTheme()),
        ctx.reduxStore.dispatch(fetchLiquidTemplates({ config })),
      ]);

      let appProps = {};

      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(ctx);
      }

      // Redirect if primary domain is present
      if (typeof window !== 'object' && !isInsideAdminView) {
        redirectSecondaryDomainToPrimaryDomain(ctx);
      }

      return {
        ...appProps,
        siteName: ctx.reduxStore.getState().currentSite.site.name,
        siteLogo: ctx.reduxStore.getState().currentSite.site.logoUrl,
        faviconUrl: ctx.reduxStore.getState().currentSite.site.faviconUrl,
        siteUrl: ctx.reduxStore.getState().currentSite.site.domain,
        customTrackingCode:
          ctx.reduxStore.getState().siteSettings.settings.customTrackingCode,
        hubName: ctx.reduxStore.getState().hub.hub.name,
        isPFESurveyEnabled:
          ctx.reduxStore
            .getState()
            .features.features.hasOwnProperty('enable_pfe_survey') &&
          ctx.reduxStore.getState().features.features.enable_pfe_survey.enabled,
        nonceValue,
      };
    }

    handlePFESurveyLinkUpdate() {
      [...document.querySelectorAll('a[href]')].forEach((tag) => {
        if (tag.href.includes('/survey_tools/')) {
          return tag.setAttribute(
            'href',
            tag.href.replace('/survey_tools/', '/surveys/'),
          );
        }
        return tag;
      });
    }

    componentDidMount() {
      if (this.props.isPFESurveyEnabled) {
        window.addEventListener('load', this.handlePFESurveyLinkUpdate);
      }
    }

    render() {
      const {
        siteName,
        siteLogo,
        faviconUrl,
        siteUrl,
        customTrackingCode,
        hubName,
        nonceValue,
      } = this.props;
      return (
        <Fragment>
          <Head>
            {!isInsideAdminView && (
              <>
                <title>{`${hubName} | ${siteName}`}</title>
                <link
                  nonce={nonceValue}
                  href={buildImgixUrl(faviconUrl)}
                  rel="icon"
                  type="image/ico"
                />

                <meta content={siteName} property="og:site_name" />
                <meta content={siteName} property="og:title" />
                <meta content={buildImgixUrl(siteLogo)} property="og:image" />
                <meta content="100" property="og:image:width" />
                <meta content="100" property="og:image:height" />
                <meta
                  content={`${i18n.t('editor:editor.website')}`}
                  property="og:type"
                />
                <meta content={siteUrl} property="og:url" />
                <meta
                  content={`${i18n.t('editor:editor.siteDescription', {
                    siteName: siteName,
                  })}`}
                  property="og:description"
                />
                <meta
                  content={`${i18n.t('editor:editor.siteDescription', {
                    siteName: siteName,
                  })}`}
                  name="Description"
                />
              </>
            )}

            <link
              rel="stylesheet"
              type="text/css"
              href="/static/assets/css/participant-header-and-footer-styles.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="/static/assets/css/user-dropdown.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="/static/assets/css/froala.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="/static/assets/css/animate-card.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              charSet="UTF-8"
              href="/static/assets/css/slick/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="/static/assets/css/slick/slick-theme.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="/static/assets/css/metismenujs.min.css"
            />
            <link
              nonce={nonceValue}
              rel="stylesheet"
              type="text/css"
              href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
            />
          </Head>
          <App {...this.props} />
          <script
            nonce={nonceValue}
            src="https://cdn.jsdelivr.net/npm/metismenujs@1.2.0/dist/metismenujs.min.js"
          />
          <script
            nonce={nonceValue}
            src="https://unpkg.com/boxicons@latest/dist/boxicons.js"
          />
          {customTrackingCode && !isInsideAdminView && (
            <script
              src="/custom_tracking_code.js"
              type="text/javascript"
              async="async"
              nonce={nonceValue}
            />
          )}
        </Fragment>
      );
    }
  };

export default withParticipantLayout;
