import 'core-js/features/object/values';
import HubPageRevision from 'api/hub-page-revision';
import FileUpload from 'lib/file-upload';
import constants from 'constants/hub-page-revision';

export const setHubPageRevision = (revision) => (dispatch) => {
  dispatch({
    type: constants.SET_HUB_PAGE_REVISION,
    revision: {
      id: revision.id,
      meta: revision.sections.meta,
      sections: revision.sections.sections,
      published: revision.published,
      updatedAt: revision.updatedAt,
    },
  });
};

export const saveHubPageRevision =
  (isPublished = false) =>
  async (dispatch, getState) => {
    const published =
      getState().hubPageRevision.revision.published || isPublished;

    dispatch({
      type: constants.SAVE_HUB_PAGE_REVISION_REQUEST,
    });

    const hubPageRevision = new HubPageRevision({
      sections: {
        meta: getState().hubPageRevision.revision.meta,
        sections: getState().hubPageRevision.revision.sections,
      },
      published,
      hub: getState().hub.hub,
    });

    try {
      const response = await hubPageRevision.save();

      dispatch({
        type: constants.SAVE_HUB_PAGE_REVISION_SUCCESS,
        id: response.id,
        meta: response.sections.meta,
        sections: response.sections.sections,
        published: response.published,
      });
    } catch (response) {
      dispatch({
        type: constants.SAVE_HUB_PAGE_REVISION_FAILURE,
        errorMessage:
          response.message || response.statusText || response.status,
        errors: hubPageRevision.errors.extract(),
      });
    }
  };

export const updateSectionTheme = (key, value, sectionId) => {
  return {
    type: constants.UPDATE_HUB_PAGE_REVISION_SECTION_THEME,
    key,
    value,
    sectionId,
  };
};

export const updateSectionData = (key, value, sectionId, index = 0) => {
  return {
    type: constants.UPDATE_HUB_PAGE_REVISION_SECTION_DATA,
    key,
    value,
    sectionId,
    index,
  };
};

export const addSectionData = (sectionId, tabId) => {
  return {
    type: constants.ADD_HUB_PAGE_REVISION_SECTION_DATA,
    sectionId,
    tabId,
  };
};

export const deleteSectionData = (sectionId, index = 0) => {
  return {
    type: constants.DELETE_HUB_PAGE_REVISION_SECTION_DATA,
    sectionId,
    index,
  };
};

export const updateSectionMeta = (key, value, sectionId) => {
  return {
    type: constants.UPDATE_HUB_PAGE_REVISION_SECTION_META,
    key,
    value,
    sectionId,
  };
};

export const addSection = (position, sectionContent) => {
  return {
    type: constants.ADD_NEW_HUB_PAGE_REVISION_SECTION,
    position,
    sectionContent,
  };
};

export const setCurrentSectionId = (id) => {
  return {
    type: constants.SET_HUB_PAGE_REVISION_CURRENT_SECTION_ID,
    id,
  };
};

export const showTemplatesDrawer = (value) => {
  return {
    type: constants.SHOW_HUB_PAGE_TEMPLATES_DRAWER,
    value,
  };
};

export const showAddNewSectionDrawer = (value) => {
  return {
    type: constants.SHOW_HUB_PAGE_ADD_NEW_SECTIONS_DRAWER,
    value,
  };
};

export const deleteSection = (position) => {
  return {
    type: constants.DELETE_HUB_PAGE_REVISION_SECTION,
    position,
  };
};

export const selectTemplate = (sections, meta) => {
  return {
    type: constants.SELECT_HUB_PAGE_REVISION_TEMPLATE,
    sections,
    meta,
  };
};

export const updateTemplateMeta = (key, value) => {
  return {
    type: constants.UPDATE_HUB_PAGE_REVISION_TEMPLATE_META,
    key,
    value,
  };
};

export const uploadFile = (file) => async (dispatch) => {
  dispatch({
    type: constants.UPLOAD_HUB_PAGE_REVISION_IMAGE_REQUEST,
  });

  try {
    const imageUrl = await FileUpload.perform(file);

    dispatch({
      type: constants.UPLOAD_HUB_PAGE_REVISION_IMAGE_SUCCESS,
      imageUrl,
    });
  } catch (response) {
    dispatch({
      type: constants.UPLOAD_HUB_PAGE_REVISION_IMAGE_FAILURE,
      errorMessage: response.statusText || response.status,
    });
  }
};

export const moveSection = (fromIndex, position) => (dispatch, getState) => {
  const sections = [...getState().hubPageRevision.revision.sections];
  const toIndex = position === 'before' ? fromIndex - 1 : fromIndex + 1;
  const element = sections[fromIndex];

  sections.splice(fromIndex, 1);
  sections.splice(toIndex, 0, element);

  dispatch({
    type: constants.MOVE_HUB_PAGE_REVISION_SECTION,
    sections,
    toIndex,
  });
};

export const reorderHubpageItems =
  (newOrder, sectionId) => async (dispatch, getState) => {
    const sectionData =
      getState().hubPageRevision.revision.sections[sectionId].data;
    const newIndexOrder = Object.values(newOrder);
    const sortedData = newIndexOrder.map((i) => sectionData[i]);

    dispatch({
      type: constants.REORDER_HUB_PAGE_ITEMS,
      data: sortedData,
      sectionId,
    });
  };

export const changeHubPageSectionKey = (key) => {
  return {
    type: constants.CHANGE_HUB_PAGE_SECTION_KEY,
    key,
  };
};

export const updateHubPageRevision = (key, value) => {
  return {
    type: constants.UPDATE_HUB_PAGE_REVISION,
    key,
    value,
  };
};

export const resetHubPageRevision = () => {
  return {
    type: constants.RESET_HUB_PAGE_REVISION,
  };
};

export const updateHubPageRevisionNonce = (value) => {
  return {
    type: constants.UPDATE_HUB_PAGE_REVISION_NONCE,
    value,
  };
};
